<template>
    <v-container fluid>

        <v-card flat class="background_color_transparent">
            <v-card-text class="py-0">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-row class="pr-0">
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="flex-nowrap">
                                        <v-col cols="12" class="mt-10">
                                            <span class="title_menu">{{  company_name }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <v-row class="border_bottom px-3">

                                                <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="company && company.name">
                                                    <v-list subheader two-line class="background_none py-0">
                                                        <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                <img v-if="company.logo !== '/img/avatar.png'" :src="company.logo" :alt="company.name">
                                                                <v-icon v-else color="primary" size="22">
                                                                    ${{'settingsIcon'}}
                                                                </v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content class="py-0 title_subtitle">
                                                                <v-list-item-title v-text="company.name" ></v-list-item-title>
                                                                <v-list-item-subtitle v-text="formatPhoneNumber(company.phone)"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-col>
                                                <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="created_at">
                                                    <v-list class="background_none py-0">
                                                        <v-list-item  class="mx-0 px-0" >
                                                            <v-list-item-content class="py-0 title_subtitle">
                                                                <v-list-item-title  v-text="$t('date_created')"></v-list-item-title>
                                                                <v-list-item-subtitle v-text="created_at"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-col>

                                            </v-row>
                                            <v-row class="px-3">
                                                <div class="description_title pl-2 pt-1 mt-0">{{$t('description')}}</div>
                                                <v-col cols="12" sm="12" class="ml-0 pl-2" v-if="!text_company_edit">
                                                    <v-hover v-slot="{ hover }">
                                                        <div v-if="text_company !== ''" @click="text_company_edit = true" class="description_text cursor_pointer">{{text_company}}
                                                            <v-btn v-if="hover"
                                                                   icon
                                                                   x-small
                                                                   @click="text_company_edit = true"
                                                            >
                                                                <v-icon color="primary">
                                                                    mdi-pencil mdi-18px
                                                                </v-icon>
                                                            </v-btn>


                                                        </div>
                                                        <div v-else >
                                                            <span class="add_text" @click="text_company_edit = true">{{ $t("add_text") }}</span>
                                                        </div>
                                                    </v-hover>
                                                </v-col>
                                                <v-col  cols="12" sm="12" class="ml-0 pl-2" v-if="text_company_edit">
                                                    <v-card elevation="0" class="m-0 p-0">
                                                        <v-card-text class="my-0 py-0 mx-0 px-0">
                                                            <v-textarea
                                                                v-model="text_company"
                                                                type="text"
                                                                class="input_textarea"
                                                                auto-grow
                                                                :disabled="loading"
                                                                no-details
                                                                outlined
                                                                hide-details
                                                            ></v-textarea>
                                                            <v-card-actions class="px-0 mx-0">
                                                                <v-btn
                                                                    color="primary"
                                                                    class="infinity_button"
                                                                    @click="CompanyTextUpdate()"
                                                                >
                                                                    {{$t('save')}}
                                                                </v-btn>
                                                                <v-btn
                                                                    text
                                                                    class="infinity_button_text px-0"
                                                                    @click="text_company_edit = false"
                                                                >
                                                                    {{$t('cancel')}}
                                                                </v-btn>

                                                            </v-card-actions>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1">
                                <v-col cols="12" md="4" class="pr-2">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <div class="d-flex ml-2">
                                                <div class="cursor-pointer_header text_uppercase" :class="display_task ? 'active' : ''"
                                                     @click="display_open('display_task')">
                                                    {{$t('task.self')}}
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4" class="pl-2">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <div class="d-flex ml-2">
                                                <div class="cursor-pointer_header text_uppercase" :class="display_invoice ? 'active' : ''"
                                                     @click="display_open('display_invoice')">
                                                    {{$t('accounts')}}
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4" class="pl-2">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <div class="px-2 cursor-pointer_header text_uppercase" :class="display_deal ? 'active' : ''"
                                                 @click="display_open('display_deal')">
                                                {{$t('deal.self')}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4" class="pl-2">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <div class="px-2 cursor-pointer_header text_uppercase" :class="display_case ? 'active' : ''"
                                                 @click="display_open('display_case')">
                                                {{$t('cases')}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4" class="pl-2">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <div class="px-2 cursor-pointer_header text_uppercase" :class="display_chat ? 'active' : ''"
                                                 @click="display_open('display_chat')">
                                                {{$t('chat')}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4" class="pl-2">
                                    <v-card class="box_shadow_block">
                                        <v-card-text>
                                            <div class="d-flex ml-2">
                                                <div class="cursor-pointer_header text_uppercase" :class="display_tracking ? 'active' : ''" @click="display_open('display_tracking')">
                                                    {{ $t('tracking') }}
                                                </div>
                                            </div>

                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1" v-if="display_task">
                                <v-col cols="12">
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-6">
                                            <v-data-table :headers="headersTask" :items="tasksItems"
                                                          :loading="loadingTask" :locale="lang"
                                                          :loading-text="$t('loading_please_wait')"
                                                          hide-default-footer
                                                          :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                          class="elevation-0"
                                                          :item-class="rowClass">
                                                <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                                    <thead>
                                                    <tr class="rowHeadClass">
                                                        <th v-for="h in headers" :key="h.value" class="table_header">
                                                            <span>{{h.text}}</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </template>

                                                <template v-slot:item.title="{ item }" class="text">
                                                    <div @click="showTask(item)" class="cursor-pointer font_weight_600">
                                                        {{ item.number }}. {{ item.title }}
                                                    </div>
                                                </template>
                                                <template v-slot:item.task_status="{ item }">
                                                    <div>{{ item.task_status }}</div>
                                                </template>
                                                <template v-slot:item.created_at="{ item }">
                                                    <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                                </template>

                                                <template v-slot:item.admin.name="{ item }">
                                                    <div>
                                                        <v-list subheader two-line class="background_none py-0">
                                                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                                    <v-icon v-else color="primary" size="22">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="py-0 title_subtitle">
                                                                    <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </template>

                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>

                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1" v-if="display_invoice">
                                <v-col cols="12">
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-6">
                                            <v-data-table :headers="headersInvoice" :items="invoiceItems"
                                                          :loading="loadingInvoice" :locale="lang"
                                                          :loading-text="$t('loading_please_wait')"
                                                          hide-default-footer

                                                          :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                          class="elevation-0"
                                                          :item-class="rowClass2">

                                                <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                                    <thead>
                                                    <tr class="rowHeadClass Table7">
                                                        <th v-for="h in headers" :key="h.value" class="table_header">
                                                            <span v-if="h.value !== 'download'" >{{h.text}}</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </template>

                                                <template v-slot:item.download="{ item }">
                                                    <div>
                                                        <v-icon color="primary" @click="downloadFile(item.request_scanned)" :title="$t('download')">mdi-cloud-upload</v-icon>
                                                    </div>
                                                </template>
                                                <template v-slot:item.amount="{ item }">
                                                    <div :class="item.deleted ? 'red--text' : ''">
                                                            <span  class="cursor-pointer font_weight_600" @click="editInvoice(item)" >
                                                                {{ item.amount }}
                                                                {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                                            </span>
                                                    </div>
                                                </template>
                                                <template v-slot:item.date="{ item }">
                                                    <div>{{ $moment(item.date).format('DD.MM.YYYY') }}</div>
                                                </template>
                                                <template v-slot:item.number_deal="{ item }">
                                                    <div v-if="item.number_deal"  class="cursor-pointer" @click="showDeal(item.deal_uuid)">{{ item.number_deal }}</div>
                                                    <div v-else>{{ $t('no') }}</div>
                                                </template>
                                                <template v-slot:item.invoice_status="{ item }">
                                                    <div class="cursor-pointer" @click="openDialogInvoiceStatus(item)">{{ item.invoice_status }}</div>
                                                </template>
                                                <template v-slot:item.company.name="{ item }">
                                                    <div>
                                                        <v-list subheader two-line class="background_none py-0">
                                                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="item.company.photo !== '/img/avatar.png'" :src="item.company.photo" :alt="item.company.name">
                                                                    <v-icon v-else color="primary" size="22">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="py-0 title_subtitle">
                                                                    <v-list-item-title v-text="item.company.name" ></v-list-item-title>
                                                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.company.phone)"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </template>
                                                <template v-slot:item.admin.name="{ item }">
                                                    <div>
                                                        <v-list subheader two-line class="background_none py-0">
                                                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                                    <v-icon v-else color="primary" size="22">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="py-0 title_subtitle">
                                                                    <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </template>

                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1" v-if="display_deal">
                                <v-col cols="12">
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-6">
                                            <v-data-table :headers="headersDeal" :items="dealItems"
                                                          :loading="loadingDealOrders" :locale="lang"
                                                          :loading-text="$t('loading_please_wait')"
                                                          hide-default-footer
                                                          :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                          class="elevation-0"
                                                          :item-class="rowClass3">
                                                <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                                    <thead>
                                                    <tr class="rowHeadClass Table5">
                                                        <th v-for="h in headers" :key="h.value" class="table_header">
                                                            <span>{{h.text}}</span>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </template>

                                                <template v-slot:item.number="{ item }">
                                                    <div :class="item.deleted ? 'red--text' : ''">
                                                                <span  class="cursor-pointer font_weight_600" @click="showDeal(item.uuid)" >
                                                                    {{ item.number }}
                                                                    {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                                                </span>
                                                    </div>
                                                </template>
                                                <template v-slot:item.deal_status="{ item }">
                                                    <div>{{ item.deal_status }}</div>
                                                </template>
                                                <template v-slot:item.created_at="{ item }">
                                                    <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                                </template>
                                                <template v-slot:item.admin="{ item }">
                                                    <div>
                                                        <v-list subheader two-line class="background_none py-0">
                                                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                                    <v-icon v-else color="primary" size="26">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="py-0 title_subtitle">
                                                                    <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </template>
                                                <template v-slot:item.active="{ item }">
                                                    <v-edit-dialog :return-value.sync="item.active" large persistent
                                                                   @save="activeDeal(item)" :save-text="$t('save')"
                                                                   :cancel-text="$t('cancel')">
                                                        <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no')}}</div>
                                                        <template v-slot:input>
                                                            <v-select v-model="item.active" :items="itemYN" item-text="name"
                                                                      item-value="id"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                      color="primary"
                                                                      class="pt-5 elevation-0 rounded-lg input_text"
                                                            ></v-select>
                                                        </template>
                                                    </v-edit-dialog>
                                                </template>
                                            </v-data-table>

                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1" v-show="display_case">
                                <v-col cols="12" >
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-3">
                                            <calendar-case ref="calendarCase"></calendar-case>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1" v-if="display_chat">
                                <v-col cols="12">
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-6" >
                                            <v-list two-line class="background_color_transparent">
                                                <v-list-item-group
                                                    v-model="selected"
                                                    class="my-0 py-0"
                                                >

                                                    <v-list-item
                                                        v-for="(item, i) in topicItems"
                                                        :key="i"


                                                        :class="'rounded-lg  ' + item.class + ' ' + ($vuetify.breakpoint.xsOnly ? 'pl-15' : 'px-3')"
                                                        class="my-4 height_list_item"
                                                        @click="openChat(item)"
                                                    >
                                                        <template v-slot:default="{ active }">
                                                            <v-app-bar
                                                                elevation="0"
                                                                absolute
                                                                class="my-5 py-0"
                                                                height="0"
                                                                v-if="!($vuetify.breakpoint.xsOnly)"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-icon class="icon_width " v-if="item.active">${{'lockOpenIcon'}}</v-icon>
                                                                <v-icon v-else class="icon_width">${{'lockIcon'}}</v-icon>

                                                            </v-app-bar>
                                                            <v-list-item-avatar size="58" class="border1_B3B3B3"   v-if="!($vuetify.breakpoint.xsOnly)">
                                                                <img v-if="item.last_user.photo " :src="item.last_user.photo" >
                                                                <v-icon v-else  size="26" class="color_B3B3B3">
                                                                    ${{'settingsIcon'}}
                                                                </v-icon>
                                                            </v-list-item-avatar>



                                                            <v-list-item-content class="ml-6 topic_padding_top0">
                                                                <v-row v-if="($vuetify.breakpoint.xsOnly)" class="my-0 py-0">
                                                                    <v-col cols="12" style="position: absolute;top:3px;left:-3px">
                                                                        <v-avatar size="58" class="border1_B3B3B3" >
                                                                            <img v-if="item.last_user.photo " :src="item.last_user.photo" >
                                                                            <v-icon v-else  size="26" class="color_B3B3B3">
                                                                                ${{'settingsIcon'}}
                                                                            </v-icon>
                                                                        </v-avatar>
                                                                    </v-col>
                                                                    <v-col cols="12" style="position: absolute; bottom: 3px; left: 12px">
                                                                        <v-icon class="icon_width" size="35" v-if="item.active">${{'lockOpenIcon'}}</v-icon>
                                                                        <v-icon v-else size="50" class="icon_width">${{'lockIcon'}}</v-icon>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="topic_margin_top0">
                                                                    <v-col cols="12"  md="4" sm="4" lg="6" class="padding_top10_bottom0" >
                                                                        <v-list-item-title class="topic_title" v-text="item.title"></v-list-item-title>
                                                                        <v-list-item-subtitle
                                                                            style="max-width: 400px"
                                                                            :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                            v-if="(item.last_message && item.last_message.type && item.last_message.type==='text') ">
                                                                            <span class="font-weight-700">{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                                                                            {{ item.last_message.value }}

                                                                        </v-list-item-subtitle>
                                                                        <v-list-item-subtitle
                                                                            style="max-width: 300px"
                                                                            :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                            v-if="(item.last_message && item.last_message.type && item.last_message.type==='file') ">
                                                                            <span class="font-weight-700">{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                                                                            {{item.last_message.file_name}}
                                                                        </v-list-item-subtitle>
                                                                        <v-list-item-subtitle
                                                                            style="max-width: 400px"
                                                                            :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                            v-if="(item.last_message && item.last_message.type && item.last_message.type==='image') ">
                                                                                <span class="font-weight-700">{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}
                                                                                    <v-img max-width="100" :src="item.last_message.value ? item.last_message.value : userAvatar" ></v-img>
                                                                                </span>
                                                                        </v-list-item-subtitle>
                                                                    </v-col>
                                                                    <v-col cols="12"  md="4" sm="4" lg="3" class="padding_top10_bottom0">
                                                                        <v-list-item-title class="topic_text">
                                                                            {{$t('last_message')}}
                                                                        </v-list-item-title>
                                                                        <v-list-item-subtitle class="topic_title">
                                                                            {{item.last_date}}
                                                                        </v-list-item-subtitle>
                                                                    </v-col>
                                                                    <v-col cols="12" md="4" sm="4" lg="3" class="padding_top10_bottom0">
                                                                        <v-list-item-title class="topic_text">
                                                                            {{$t('application_status')}}
                                                                        </v-list-item-title>
                                                                        <v-list-item-subtitle class="topic_title" >
                                                                            {{item.bot ? $t('system_message') : (item.active ? $t('open_application') : $t('closed_application')) }}
                                                                        </v-list-item-subtitle>
                                                                    </v-col>
                                                                </v-row>


                                                            </v-list-item-content>

                                                        </template>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="py-0 mt-1" v-if="display_tracking">
                                <v-col cols="12" >
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-3">
                                            <v-row v-if="trackings.length > 0">
                                                <v-col cols="12" class="my-0 py-0 pt-5">
                                                    <v-card flat class="background_color_transparent">
                                                        <v-card
                                                            elevation="0"
                                                            :hover="true"
                                                            v-for="(item, i) in trackings"
                                                            :key="'tracking_'+i"
                                                            class="mb-7 rounded-lg"
                                                            :class="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 'text-center' :''"
                                                            @click="showDetailsApplication(item)"
                                                            color="#F5F5F5"

                                                        >

                                                            <v-card-text class="px-0 py-0">

                                                                <v-row class="px-5 pb-2">
                                                                    <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'text-left deal_col_50' : ''">
                                                                        <div class="mb-4 deal_title">
                                                                            <div class="deal_text mb-1">{{$t('date_created')}}</div>
                                                                            {{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}
                                                                        </div>
                                                                        <div class="mb-2 deal_title">
                                                                            <div class="deal_text mb-1">{{$t('container_number')}}</div>
                                                                            {{item.container_number ? item.container_number : $t('no_data') }}
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="8" :class="$vuetify.breakpoint.xsOnly ? 'text-right deal_col_50' : ''">
                                                                        <div class="mb-4 deal_title">
                                                                            <div class="deal_text mb-1">{{$t('direction')}}</div>
                                                                            {{item.departure}} - {{item.destination}}
                                                                        </div>
                                                                        <div v-if="item.day > 0" class="deal_title">
                                                                            <div class="deal_text mb-1">{{$t('in_operation_for')}}</div>
                                                                            {{ $t('for_day', {"day":item.day})}}
                                                                        </div>


                                                                    </v-col>
                                                                    <v-col cols="12" v-if="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                                        <v-btn type="button"
                                                                               outlined
                                                                               color="primary"
                                                                               class="rounded-lg but_enter"
                                                                               block
                                                                               :height="$vuetify.breakpoint.xsOnly ? 45 : 35">
                                                                            {{ $t('details') }}
                                                                        </v-btn>
                                                                    </v-col>
                                                                    <v-col cols="12" class="pb-7" v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                                        <v-row >
                                                                            <v-col cols="12">
                                                                                <ul class="pod_status d-flex">
                                                                                    <template v-for="(status_item, i) in statusItems"  >
                                                                                        <li :key="'status'+status_item.id" class="li1"><div class="node " :class="item.status_id >= status_item.id ? 'blue_point' : 'grey_point'"></div><p :class="(i+1) === statusItems.length ? 'li_last' : ''"><span :class="item.status_id >= status_item.id ? 'blue_color' : ''" >{{status_item.name}}</span></p></li>
                                                                                        <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== statusItems.length" class="my_divider " :class="item.status_id > status_item.id ? 'blue_line' : 'grey_line'">
                                                                                        </li>
                                                                                    </template>
                                                                                </ul>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>

                                                                </v-row>


                                                            </v-card-text>
                                                        </v-card>

                                                    </v-card>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row class="py-0 mt-1">
                                <v-col cols="12" >
                                    <v-card class="box_shadow_block">
                                        <v-card-text class="px-3">
                                            <v-row v-for="item in paymentAccountCompaniesItems" :key="item.id">
                                                <v-col cols="12" sm="4">
                                                    <label class="client_title">{{$t('bank_name')}}</label>
                                                    <div class="client_text">{{item.name}}</div>
                                                </v-col>
                                                <v-col cols="12" sm="3" >
                                                    <label class="client_title">{{$t('BIC_bank')}}</label>
                                                    <div class="client_text">{{item.bik}}</div>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <label class="client_title">{{$t('account_number')}}</label>
                                                    <div class="client_text">{{item.currency.letter_code}}: {{item.payment_account}}</div>
                                                </v-col>
                                                <v-col cols="12" sm="1" class="d-flex justify-end">
                                                    <v-btn @click="deletePaymentAccountCompany(item)" icon>
                                                        <v-icon>mdi-delete-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="($route.name === 'company.show' && addBank)">
                                                <v-col cols="12" class="pt-5 pb-0 my-0 text-right">
                                                    <v-btn
                                                        icon
                                                        x-small
                                                        @click="addBank = false"
                                                    >
                                                        <v-icon>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="py-2 pt-0" cols="12" sm="8">
                                                    <label class="input_label">{{$t('bank_name')}}</label>
                                                    <ValidationProvider ref="bank_name" rules="required|min:2|max:255"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="bank_name" type="text"
                                                                      :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :hide-details="(valid || bank_name=== null) ? true : false"
                                                                      :label="$t('bank_name')"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      height="46"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable>
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col class="py-2 pt-0" cols="12" sm="4">
                                                    <label class="input_label">{{$t('BIC_bank')}}</label>
                                                    <ValidationProvider ref="bik_bank" rules="required|min:2|max:50"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="bik_bank" type="text"
                                                                      :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :hide-details="(valid || bik_bank=== null) ? true : false"

                                                                      :label="$t('BIC_bank')"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      height="46"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable>
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col class="py-2" cols="12" sm="4">
                                                    <label class="input_label">{{$t('currency')}}</label>
                                                    <ValidationProvider ref="currency" rules="required" v-slot="{ errors, valid }">
                                                        <v-select v-model="currency" :items="currencyItems"
                                                                  item-text="letter_code" item-value="id"
                                                                  :error-messages="errors"
                                                                  :disabled="loading"
                                                                  :hide-details="(valid || currency=== null) ? true : false"

                                                                  :label="$t('currency')"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  height="46"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable
                                                        ></v-select>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col class="py-2" cols="12" sm="8">
                                                    <label class="input_label">{{$t('account_number')}}</label>
                                                    <ValidationProvider ref="payment_account" rules="required|min:2|max:150"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="payment_account" type="text"
                                                                      :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :hide-details="(valid || payment_account=== null) ? true : false"

                                                                      :label="$t('account_number')"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      height="46"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable>
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col class="py-3 d-flex justify-end" cols="12" sm="12">
                                                    <v-btn type="text" :loading="loading" color="primary"
                                                           dense
                                                           class="infinity_button"
                                                           @click="savePaymentAccountCompany()"
                                                           :disabled="invalid || loading"
                                                    >
                                                        {{ $t('add') }}
                                                    </v-btn>
                                                </v-col>

                                            </v-row>
                                            <v-row v-if="($route.name === 'company.show' && !addBank)">
                                                <v-col class="py-3" cols="12" >
                                                    <v-btn type="text"
                                                           color="primary"
                                                           class="infinity_button"
                                                           @click="addBank = true"
                                                    >
                                                        {{ paymentAccountCompaniesItems.length > 0 ? $t('add_more') : $t('add_checking_account') }}
                                                    </v-btn>

                                                </v-col>

                                            </v-row>


                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12" md="4" class="pb-10" style="background-color: #F5F5F5">
                            <v-row class="mx-0">
                                <v-col cols="12" class="pa-0">
                                    <v-card class="box_shadow_block" style="margin-top: 30px">
                                        <div>
                                            <div class="d-flex">
                                                <div class="pa-4 participants mr-auto">{{ $t('clients') }}</div>
                                                <div v-if="false" class="pa-4 pb-2">
                                                    <v-icon
                                                        size="15"
                                                        class="add_icon"
                                                    >
                                                        mdi-plus-circle-outline
                                                    </v-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="12" xl="6" class="py-1" v-for="client in clients"
                                                       :key="'client_'+client.id">
                                                    <v-list class="my-0 py-0">
                                                        <template>
                                                            <v-list-item>
                                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                    <img v-if="client.photo  !== '/img/avatar.png'" :src="client.photo" :alt="client.name">
                                                                    <v-icon v-else color="primary" size="22">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="py-0 title_subtitle">
                                                                    <v-list-item-title v-text="client.name"></v-list-item-title>
                                                                    <v-list-item-subtitle v-text="formatPhoneNumber(client.phone)"></v-list-item-subtitle>
                                                                </v-list-item-content>

                                                            </v-list-item>
                                                        </template>
                                                    </v-list>
                                                </v-col>
                                             </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" class="pa-0" v-if="phones.length > 0">
                                    <v-card class="box_shadow_block px-4" style="margin-top: 15px">
                                        <div class="participants">
                                            <div>
                                                <div class="d-flex">
                                                    <div class="py-4 participants mr-auto">{{ $t('phones') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(phone, index) in phones" :key="'phone_'+index">
                                            <div @click="phone.show = false"  v-if="!phone.show" class="client_text py-3">
                                                {{formatPhoneNumber(phone.phone)}}
                                            </div>
                                            <v-row v-else>
                                                <v-col cols="12" >
                                                    <ValidationProvider ref="phone.phone" rules="required|min:1|max:255"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="phone.phone" type="tel"
                                                                      autofocus

                                                                      :error="!valid" :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :label="$t('phone')"
                                                                      hide-details
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                                      clearable>
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" class="pa-0" v-if="email">
                                    <v-card class="box_shadow_block px-4" style="margin-top: 15px">
                                        <div class="participants">
                                            <div>
                                                <div class="d-flex">
                                                    <div class="py-4 participants mr-auto">{{ $t('emails') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="client_text py-3">
                                                {{email}}
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" class="pa-0" v-if="addresses.length > 0">
                                    <v-card class="box_shadow_block px-4" style="margin-top: 15px">
                                        <div class="participants">
                                            <div>
                                                <div class="d-flex">
                                                    <div class="py-4 participants mr-auto">{{ $t('addresses') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="address in addresses" :key="'address_'+address.address" class="client_text py-3">
                                            <div @click="address.show = false" v-if="!address.show">
                                                {{address.address}}
                                            </div>
                                            <v-row v-else>
                                                <v-col cols="12" sm="12">
                                                    <ValidationProvider ref="address.address" rules="required|min:1|max:255"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="address.address" type="tel"
                                                                      autofocus
                                                                      :error="!valid" :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :label="$t('address')"
                                                                      hide-details
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                                      clearable>
                                                        </v-text-field>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" class="pa-0">
                                    <v-card class="box_shadow_block px-4" style="margin-top: 15px">
                                        <div class="participants pb-4">
                                            <div>
                                                <div class="d-flex">
                                                    <div class="py-4 participants mr-auto">{{ $t('additional_information') }}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('company_name')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="company_name && company_name !== 'null'">{{ company_name }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('name_director')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="name_director && name_director !== 'null'">{{ name_director }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('company_name_legal')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="name_legal && name_legal !== 'null'">{{ name_legal }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('property')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="form_property.name && form_property.name !== 'null'">{{ form_property.name }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('address_legal')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="address_legal && address_legal !== 'null'">{{ address_legal }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('address_actually')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="address_actually && address_actually !== 'null'">{{ address_actually }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('bin_iin')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="bin_iin && bin_iin !== 'null'">{{ bin_iin }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('contract_number')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="contract_number && contract_number !== 'null'">{{ contract_number }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="6" class="client_title">{{$t('date_contract')}}</v-col>
                                            <v-col cols="12" sm="6" class="client_text">
                                                <div>
                                                    <span v-if="date_contract && date_contract !== 'null'">{{ date_contract }}</span>
                                                    <span v-else class="client_no_text">{{$t('no_data')}}</span>
                                                </div>

                                            </v-col>
                                        </v-row>



                                    </v-card>
                                </v-col>

                            </v-row>

                            <v-row>

                            </v-row>
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-card-text>
        </v-card>


        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogInvoiceStatus"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('invoice_editing') }}</span>
                    </div>
                    <div>
                        <v-icon @click="closeInvoiceStatus()" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="invoice_status_edit" rules="required"
                                            v-slot="{ errors, valid }">
                            <v-select v-model="invoice_status_edit" :disabled="loading"
                                      :items="invoiceStatusEditItems" :error-messages="errors"
                                      :error="!valid"
                                      item-text="name" item-value="id"
                                      :label="$t('invoice_status')"
                                      outlined
                                      solo
                                      flat
                                      dense
                                      hide-details
                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                      color="primary"
                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="paymentConfirmation()"
                    >{{ $t('edit') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogStatusApproval"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('reason_for_rejecting_uved') }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogStatusApproval = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                            v-slot="{ errors, valid }">
                            <v-textarea
                                solo
                                :label="$t('write_reason_for_the_rejection')"
                                auto-grow
                                outlined
                                rows="8"
                                row-height="25"
                                v-model="reject_text"
                                :error-messages="errors"
                                :disabled="loading"
                                class="input_textarea"
                                no-details
                                hide-details
                                full-width
                            >
                            </v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="addStatusApproval('reject')"
                    >{{ $t('reject') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog
            v-model="dialogDetailTracking"
            :width="!$vuetify.breakpoint.xsOnly ? 700 : '100%'"
            overlay-color = "#edebeb"
            :content-class="!$vuetify.breakpoint.xsOnly ? 'rounded-lg border1_B3B3B3' : ''"
            transition="dialog-bottom-transition"

            :fullscreen="$vuetify.breakpoint.xsOnly"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :scrollable="$vuetify.breakpoint.xsOnly"

        >
            <v-card>
                <v-card-title class="deal_dialog_padding">
                    <v-row class="mx-0 px-0 py-0 my-0">
                        <v-col cols="12" class="mx-0 px-0 py-0 my-0">
                            <v-row>
                                <v-col cols="10" class="ma-auto">{{$t('more_application')}}</v-col>
                                <v-col cols="2" class="text-right"><v-icon size="35" @click="close_info()">${{'closeIcon'}}</v-icon></v-col>
                            </v-row>
                            <div class="text_left">
                                <v-chip  v-for="(item, z) in data_info" :key="'data_info'+z"
                                         small
                                         class="mr-2 font-size-11"
                                         :class="item.class"
                                         :color="item.color"
                                         label
                                         outlined
                                         @click="show_info(item.type)"
                                >{{item.name}}</v-chip>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="mx-0 px-0 py-0 my-0">
                    <v-row class="mx-0 px-0 py-0 my-0">
                        <v-col cols="12" class="deal_dialog_padding pt-0"  v-if="info_type==='data'">
                            <v-row class="mx-0 px-0 py-0 my-0">
                                <v-col cols="12" md="6" class="mx-0 px-0 py-0 my-0">
                                    <v-row class="mx-0 px-0 py-0 my-0">
                                        <v-col cols="12" class="deal_in_detail">{{ $t('application_number') }}: <b>{{ detail_tracking.number ? detail_tracking.number :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('container_number') }}: <b>{{ detail_tracking.container_number ? detail_tracking.container_number :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('departures') }}: <b>{{ detail_tracking.departure ? detail_tracking.departure :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('destination') }}: <b>{{ detail_tracking.destination ? detail_tracking.destination :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('type_container') }}: <b>{{ detail_tracking.type_container ? detail_tracking.type_container :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('type_transportation') }}: <b>{{ detail_tracking.type_transport ? detail_tracking.type_transport :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('download_type') }}: <b>{{ (detail_tracking.loading_type && detail_tracking.loading_type.name) ? detail_tracking.loading_type.code +' '+ detail_tracking.loading_type.name :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('application_date') }}: <b>{{ detail_tracking.created_at ? $moment(detail_tracking.created_at).format('YYYY-MM-DD HH:mm') : $t('no_data')  }}</b></v-col>

                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('date_operation') }}: <b>{{ detail_tracking.date_operation  ? $moment(detail_tracking.date_operation).format('YYYY-MM-DD HH:mm') : $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('date_of_arrival_at_the_border') }}: <b>{{ detail_tracking.date_of_arrival_at_border ? $moment(detail_tracking.date_of_arrival_at_border).format('YYYY-MM-DD HH:mm') :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12" class="deal_in_detail">{{ $t('arrival_date_at_destination') }}: <b>{{ detail_tracking.arrival_date_at_destination ? $moment(detail_tracking.arrival_date_at_destination).format('YYYY-MM-DD HH:mm') :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('application_status') }} : <b>{{ detail_tracking.status ? detail_tracking.status :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('distance_to_destination') }}: <b>{{ detail_tracking.distance_to_destination ? detail_tracking.distance_to_destination :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('delivered') }}: <b>{{ detail_tracking.paid == true ? $t('yes') :  $t('no')  }}</b></v-col>
                                        <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('company') }}: <b>{{ detail_tracking.company && detail_tracking.company.name ? detail_tracking.company.name  :  $t('no_data')  }}</b></v-col>
                                        <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('bin_iin') }}: <b>{{ detail_tracking.company && detail_tracking.company.bin_iin ?  detail_tracking.company.bin_iin :  $t('no_data')  }}</b></v-col>

                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="info_type==='tracking'">
                            <v-row>
                                <v-col cols="12" class="rounded-lg" style="background-color: #CDDAE1">
                                    <v-row>
                                        <v-col cols="12" md="4" >
                                            <ul class="station">
                                                <li v-if="tracking_geo.from">
                                                    <div class="node blue_point" ></div>
                                                    <p class="font-primary text_left">{{tracking_geo.from.name}} <span>{{tracking_geo.from.date_operation}}</span></p>
                                                </li>
                                                <li><div class="divider blue_line"></div></li>

                                                <template v-for="(item, t) in tracking_geo.tracking"  >
                                                    <li :key="'geo'+item.id" class="text_left">
                                                        <div class="node blue_point"></div>
                                                        <p class="font-primary text_left">{{item.name}}<span>{{item.date_operation}}</span></p>
                                                    </li>
                                                    <li :key="'geo'+item.name"><div :class="(t+1)===tracking_geo.tracking.length ? 'grey_line' : 'blue_line'" class="divider"></div></li>
                                                </template>


                                                <li v-if="tracking_geo.to">
                                                    <div class="node grey_point"></div>
                                                    <p class="font-primary text_left">{{tracking_geo.to.name}} <span>{{tracking_geo.to.date_operation}}</span></p>
                                                </li>

                                            </ul>

                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <div style="height: 310px;" class="rounded-lg my-0 py-0">
                                                <yandex-map class="py-0 my-0 py-0" v-if="coords && showMap" ref="map"
                                                            :coords="coords"
                                                            :controls="[]"
                                                            :show-all-markers="showAllMarkers"
                                                            :zoom="zoom"
                                                            @map-was-initialized="initHandler"
                                                            style="background-color: #ffffff">
                                                </yandex-map>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce"
    import CalendarCase from "../components/CalendarCase.vue";
    import {loadYmap, yandexMap} from 'vue-yandex-maps'

    export default {
        name: 'CompanyShow',
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            CalendarCase,
            yandexMap
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                tab: 0,
                phoneRaw: null,
                phoneAdditionalRaw: null,
                company: {},
                name_legal: null,
                name_director: null,
                address_actually: null,
                address_legal: null,
                contract_number: null,
                company_name: null,
                company_uuid: null,
                email: null,
                bin_iin: null,
                logo: null,
                description: null,
                active: false,
                deleted: false,
                loading: false,
                loadingClients: false,
                date_contract: null,
                dateDialogContract: false,
                clients: [],
                clientItems: [],
                clientSearching: null,
                exist_translations: {},
                all_translations: true,

                property: null,
                propertyItems: [],
                form_property: {},
                paymentAccountCompaniesItems: [],
                addBank: false,
                bank_name: null,
                bik_bank: null,
                payment_account: null,
                currency: null,
                currencyItems: [],
                contract: null,
                data_contract: {},
                new_contract: 0,


                created_at: null,
                text_company: '',
                text_company_edit: false,
                display_deal: false,
                display_task: false,
                display_invoice: false,
                display_case: false,
                display_tracking: false,
                display_chat: false,
                invoiceItems: [],
                headersInvoice: [
                    {
                        text: this.$t('amount'),
                        align: "left",
                        sortable: true,
                        value: "download"
                    },
                    {
                        text: this.$t('amount'),
                        align: "left",
                        sortable: true,
                        value: "amount"
                    },
                    {
                        text: this.$t('number_deal'),
                        align: "left",
                        sortable: true,
                        value: "number_deal"
                    },
                    {
                        text: this.$t('invoice_status'),
                        align: "left",
                        sortable: true,
                        value: "invoice_status"
                    },
                    {
                        text: this.$t('date'),
                        align: "left",
                        sortable: true,
                        value: "date"
                    },
                    {
                        text: this.$t('company'),
                        align: "left",
                        sortable: true,
                        value: "company.name"
                    },
                    {
                        text: this.$t('manager'),
                        align: "left",
                        sortable: true,
                        value: "admin.name"
                    }
                ],
                tasksItems: [],
                headersTask: [
                    {
                        text: this.$t('task_name'),
                        align: "left",
                        sortable: true,
                        value: "title"
                    },
                    {
                        text: this.$t('task_status'),
                        sortable: false,
                        value: "task_status"
                    },
                    {
                        text: this.$t('date'),
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('author'),
                        align: "left",
                        sortable: false,
                        value: "admin.name"
                    },
                ],
                dealItems: [],
                headersDeal: [
                    {
                        text: this.$t('number_deal'),
                        align: "left",
                        sortable: true,
                        value: "number"
                    },
                    {
                        text: this.$t('deal_status'),
                        sortable: false,
                        value: "deal_status"
                    },
                    {
                        text: this.$t('date'),
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('author'),
                        align: "left",
                        sortable: false,
                        value: "admin"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active"
                    },
                ],
                selected: [],
                topicItems: [],
                loadingDealOrders:true,

                invoice_status_edit: null,
                invoiceStatusEditItems: [],
                dialogInvoiceStatus:false,
                loadingTracking:true,
                trackings: [],
                data_info: [
                    {
                        "type": "data",
                        "name" : this.$t('data'),
                        "class": "mr-2",
                        "color": "primary",
                    },
                    {
                        "type": "tracking",
                        "name": this.$t('tracking'),
                        "class": "border_0",
                        "color": "color_B3B3B3"
                    }
                ],
                info_type: "data",
                tracking_geo: [],
                referencePoints: [],
                showAllMarkers: true,
                mapMarker: null,
                zoom: 12,
                ymaps: null,
                coordinates: [],
                showMap: false,
                coordinatesPolygon: [],
                dialogDetailTracking: false,
                detail_tracking: {},
                statusItems: [],
                dialogStatusApproval: false,
                loadingMessage: false,
                loadingSex: false,
                loadingTypeActivity: false,
                loadingTypeTransport: false,
                loadingAttractionChannel: false,
                loadingInvoice: true,
                loadingTask: true,
                loadingTopic: false,
                itemYN: [
                    {
                        id: 0,
                        name: this.$t('no'),
                    },
                    {
                        id: 1,
                        name: this.$t('yes'),
                    }
                ],
                phones: [],
                addresses: [],
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            phoneAdditional: function () {
                return this.changePhone(this.phoneAdditionalRaw)
            },
            languages() {
                return this.listLanguages
            },
            coords: {
                get: function () {
                    return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
                },
                set: function (val) {
                    this.coordinates = (val && val.length === 2) ? val : [51, 71]
                }
            },
            mapSettings() {
                this.$yandex.lang = 'ru_RU'
                return this.$yandex
            },
        },
        watch: {
            clientSearching: debounce(function (val) {
                if (val) {
                    this.getClients(val)
                }
            }, 500)
        },
        async mounted() {
            this.language = this.languages[this.tab]

            await this.checkCreate();
            await this.getFormProperties();
            await this.getCurrencies();
            await this.getInvoices()
            await this.getTasks()
            await this.getDeals()
            await this.getTopic()
            await this.getInvoiceStatuses()
            await this.getStatus()
            await this.getTracking()
            if (this.$refs.calendarCase) {
                this.getCalendarCase();
            }

        },
        methods: {
            async setLanguage(val) {
                this.language = this.languages[val]
                await this.checkCreate()
            },
            clearEmptyClientItems(items) {
                if (items && items.length > 0) {
                    this.clients = items.filter(item => typeof item === 'object')
                }
            },
            clearClients() {
                if (!this.clients) {
                    this.clientItems = []
                }
            },

            checkCreate() {
                if (this.$route.name === "company.create") {
                    this.heading = this.$t('company_creation')
                } else {
                    this.heading = this.$t('company_editing')
                    if (this.$route.params.id) {
                        this.getCompany();
                    }
                }
            },
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
            },
            rowClass2(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table7' : 'rowMobiClass';
            },
            rowClass3(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
            },
            display_open(type){
                switch (type){
                    case 'display_task':
                        this.display_invoice = false;
                        this.display_chat = false;
                        this.display_case = false;
                        this.display_tracking = false;
                        this.display_deal = false;
                        this.display_task = (this.display_task) ? false : true;
                        break;
                    case 'display_invoice':
                        this.display_invoice = (this.display_invoice) ? false :true;
                        this.display_task = false;
                        this.display_case = false;
                        this.display_deal = false;
                        this.display_chat = false;
                        this.display_tracking = false;
                        break;
                    case 'display_case':
                        this.display_case = (this.display_case) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_deal = false;
                        this.display_chat = false;
                        this.display_tracking = false;
                        break;
                    case 'display_tracking':
                        this.display_tracking = (this.display_tracking) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_deal = false;
                        this.display_chat = false;
                        this.display_case = false;
                        break;
                    case 'display_deal':
                        this.display_deal = (this.display_deal) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_chat = false;
                        this.display_tracking = false;
                        this.display_case = false;
                        break;
                    case 'display_chat':
                        this.display_chat = (this.display_chat) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_deal = false;
                        this.display_tracking = false;
                        this.display_case = false;
                        break;
                }
            },

            closeInvoiceStatus(){
                this.dialogInvoiceStatus  = false
                this.invoice_status_edit = null
                this.invoice_id = null
            },
            openDialogInvoiceStatus(item){
                this.invoice_id = item.id
                this.invoice_status_edit = item.invoice_status_id
                this.dialogInvoiceStatus  = true
            },
            async getInvoiceStatuses() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/invoice_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.invoice_statusItems = res.body.data
                        this.invoiceStatusEditItems = res.body.data
                        this.system_filters = res.body.data
                    })
                    .catch(err => {
                        this.invoice_statusItems = [];
                        this.invoiceStatusEditItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_invoice_statuses'))
                    });
            },
            async paymentConfirmation() {

                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.invoice_id) {
                    formData.append('invoice_id', this.invoice_id)
                }
                if (this.invoice_status_edit) {
                    formData.append('invoice_status_edit', this.invoice_status_edit)
                }


                await this.$http
                    .put(`admin/invoice/${this.invoice_id}/status`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('invoice_has_been_updated'))
                        this.closeInvoiceStatus();
                        this.getInvoices();
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        if(prop ==='answer_scanned' || prop ==='request_scanned'){
                                            this.$toastr.error(err.body.errors[prop][0])
                                        }
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            editInvoice(item) {
                this.$router.push({
                    name: 'invoice.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getInvoices() {
                let params = {};
                params.sortBy = 'id';
                params.sortDir =  'desc';
                params.page = 1
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                if(params.company){
                    this.loadingInvoice = true;
                    await this.$http
                        .get("admin/invoice", {
                            params: params,
                        })
                        .then(res => {
                            this.invoiceItems = res.body.data
                        })
                        .catch(err => {
                            this.invoiceItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_invoices'))
                        })
                        .finally(end => {
                            this.loadingInvoice = false
                        })
                }
            },
            async getTasks() {
                let params = {};
                params.sortBy = 'id';
                params.sortDir =  'desc';
                params.page = 1
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                if(params.company) {
                    this.loadingTask = true;
                    await this.$http
                        .get("admin/task", {
                            params: params,
                        })
                        .then(res => {
                            this.tasksItems = res.body.data
                        })
                        .catch(err => {
                            this.tasksItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                        })
                        .finally(end => {
                            this.loadingTask = false
                        })
                }
            },
            showTask(item) {
                this.$router.push({
                    name: 'task.show',
                    params: {
                        id: item.uuid
                    },
                })
                this.forceRerender()
            },
            showDeal(uuid) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: uuid
                    }
                })
            },
            async getDeals() {
                let params = {};
                params.sortBy = 'id';
                params.sortDir =  'desc';
                params.page = 1
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                if(params.company) {
                    this.loadingDealOrders = true;
                    await this.$http
                        .get("admin/deal", {
                            params: params,
                        })
                        .then(res => {
                            this.dealItems = res.body.data
                        })
                        .catch(err => {
                            this.dealItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_deals'))
                        })
                        .finally(end => {
                            this.loadingDealOrders = false
                        })
                }
            },

            async getStatus() {
                var _this = this;
                this.loadingSataus = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }



                await this.$http
                    .get(`admin/status`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.statusItems = res.body.data

                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingSataus = false
                    })
            },
            async getTracking() {
                var _this = this;
                this.progress = 0
                this.loadingTracking = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                if(params.company) {
                    await this.$http
                        .get(`admin/tracking`, {
                            params: params,
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.trackings = res.body.data

                        })
                        .catch(err => {
                            this.trackings = [];
                            this.$toastr.error(this.$t('failed_to_get_list_trackings'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loadingTracking = false
                        })
                }
            },
            showDetailsApplication(item){
                this.detail_tracking = {};
                this.detail_tracking = item;
                this.dialogDetailTracking = true;

            },
            show_info(type){
                this.info_type = type;
                if(type === 'tracking'){

                    let mclass = this.data_info[0].class;
                    let mcolor = this.data_info[0].color;

                    this.data_info[0].class = this.data_info[1].class
                    this.data_info[0].color = this.data_info[1].color

                    this.data_info[1].class= mclass;
                    this.data_info[1].color = mcolor;

                    this.getTrackingDeal()
                }
                else if(this.data_info[0].color !== 'primary'){
                    let mclass = this.data_info[1].class;
                    let mcolor = this.data_info[1].color;

                    this.data_info[1].class = this.data_info[0].class
                    this.data_info[1].color = this.data_info[0].color

                    this.data_info[0].class= mclass;
                    this.data_info[0].color = mcolor;
                }

            },
            close_info(){
                this.detail_tracking = {};
                this.dialogDetailTracking = false;
            },
            async getTrackingDeal() {
                var _this = this;
                this.loading = true;

                let params = {};


                await this.$http
                    .get("admin/tracking/deal/"+this.detail_tracking.deal_uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.referencePoints = []
                        this.tracking_geo = res.body.data.tracking[0];
                        this.coordinates = this.tracking_geo.from.point

                        this.referencePoints.push(this.tracking_geo.from.point);
                        if(this.tracking_geo && this.tracking_geo.tracking)
                        {
                            for (var i=0; i<this.tracking_geo.tracking.length; i++){
                                this.referencePoints.push(this.tracking_geo.tracking[i].point)
                            }
                        }


                        this.ymapInit()
                    })
                    .catch(err => {
                        this.tracking_geo = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async ymapInit() {
                var _this = this
                if (window.ymaps) {
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }
                await loadYmap({...this.mapSettings, debug: true})
                if (window.ymaps) {
                    window.ymaps.ready(function () {
                        _this.showMap = true
                    })
                }
            },
            initHandler(e) {
                var _this = this;
                if (this.coords && this.coords.length !== 2 && window.ymaps) {
                    var location = window.ymaps.geolocation.get({
                        provider: 'yandex',
                        mapStateAutoApply: true,
                        autoReverseGeocode: true
                    })
                    location.then(
                        function (result) {
                            //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                            e.geoObjects.add(result.geoObjects)
                        },
                        function (err) {
                            console.log('Error: ' + err)
                        }
                    )
                }
                e.geoObjects.options.set('draggable', true)
                _this.myMap = e;
                var multiRoute = new window.ymaps.multiRouter.MultiRoute({
                    // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
                    referencePoints: this.referencePoints,
                }, {
                    // Внешний вид путевых точек.
                    wayPointStartIconColor: "#FFFFFF",
                    wayPointStartIconFillColor: "#B3B3B3",
                    // Внешний вид линии активного маршрута.
                    routeActiveStrokeWidth: 4,
                    routeActiveStrokeStyle: 'solid',
                    routeActiveStrokeColor: "#007EC9",
                    // Внешний вид линий альтернативных маршрутов.
                    routeStrokeStyle: 'dot',
                    routeStrokeWidth: 3,
                    // Автоматически устанавливать границы карты так,
                    // чтобы маршрут был виден целиком.
                    boundsAutoApply: true
                });


                _this.myMap.geoObjects.add(multiRoute);
                //e.geoObjects.add(multiRoute);

                var fullscreenControl = new window.ymaps.control.FullscreenControl();
                _this.myMap.controls.add(fullscreenControl);
                //  e.controls.add('FullscreenControl');
            },

            async getTopic() {
                var _this = this;
                this.loadingTopic = true;
                let params = {};
                if (this.language) {
                    params.language = this.language
                }

                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                params.filter = 'topic';
                if(params.company) {
                    await this.$http
                        .get(`admin/topic`, {
                            params: params,
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.topicItems = res.body.data
                        })
                        .catch(err => {
                            this.topicItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_topics'))
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loadingTopic = false
                        })
                }
            },
            openChat(item){
                this.$router.push({
                    name: 'topic.message',
                    params: {
                        id: item.uuid
                    }
                })
            },

            getCalendarCase(){
                let id_array = 1;
                this.$refs.calendarCase.getCaseAdmin('company', id_array);
            },




            async getCurrencies() {
                let params = {};
                params.filter = 'search';
                await this.$http
                    .get(`admin/currency`, {
                        params: params,
                    })
                    .then(res => {
                        this.currencyItems = res.body.data
                    })
                    .catch(err => {
                        this.currencyItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_currencies'))
                    });
            },
            async getFormProperties() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/property`, {
                        params: params,
                    })
                    .then(res => {
                        this.propertyItems = res.body.data
                    })
                    .catch(err => {
                        this.propertyItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_properties'))
                    });
            },
            async getClients(str) {
                if (str) {
                    this.loadingClients = true
                    let params = {};
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.client = str;
                    }
                    await this.$http
                        .get("admin/client", {
                            params: params,
                        })
                        .then(res => {
                            this.clientItems = res.body.data;
                        })
                        .catch(err => {
                            this.clientItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_clients'))
                        })
                        .finally(end => {
                            this.loadingClients = false
                        })
                }
            },
            async getCompany() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {};
                if (this.languages && this.languages[this.tab]) {
                    params.language = this.languages[this.tab]
                }
                await this.$http
                    .get(`admin/company/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.company = res.body.data
                        this.company_name = res.body.data.name
                        this.text_company = res.body.data.note
                        this.company_uuid = res.body.data.uuid
                        this.name_legal = res.body.data.name_legal
                        this.property = res.body.data.form_property_id
                        this.form_property = res.body.data.form_property
                        this.name_director = res.body.data.name_director
                        this.address_actually = res.body.data.address_actually
                        this.address_legal = res.body.data.address_legal
                        this.contract_number = res.body.data.contract_number
                        this.phoneRaw = res.body.data.phone
                        this.phones = res.body.data.phones
                        this.addresses = res.body.data.addresses
                        this.phoneAdditionalRaw = res.body.data.phone_additional
                        this.bin_iin = res.body.data.bin_iin
                        this.email = res.body.data.email
                        this.date_contract = res.body.data.date_contract
                        this.active = res.body.data.active
                        this.deleted = res.body.data.deleted
                        this.description = res.body.data.description
                        this.logo = res.body.data.logo
                        this.clients = res.body.data.clients
                        this.created_at = res.body.data.created_at
                        this.paymentAccountCompaniesItems = res.body.data.payment_accounts
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations

                        if(res.body.data && res.body.data.contract){
                            this.data_contract = res.body.data.contract
                        }
                        if(res.body.data.contract_base64){
                            this.contract  = new File([atob(res.body.data.contract_base64)], res.body.data.contract.file_name, {
                                type: "application/pdf",
                            })
                        }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_company'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveCompany() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.property) {
                    formData.append('property', this.property)
                }
                if (this.company) {
                    formData.append('company', this.company)
                }
                if (this.name_legal) {
                    formData.append('name_legal', this.name_legal)
                }
                if (this.name_director) {
                    formData.append('name_director', this.name_director)
                }
                if (this.phone) {
                    formData.append('phone', this.phone)
                }
                if (this.phoneAdditional) {
                    formData.append('phone_additional', this.phoneAdditional)
                }
                if (this.email) {
                    formData.append('email', this.email)
                }
                if (this.bin_iin) {
                    formData.append('bin_iin', this.bin_iin)
                }
                if (this.address_actually) {
                    formData.append('address_actually', this.address_actually)
                }
                if (this.address_legal) {
                    formData.append('address_legal', this.address_legal)
                }
                if (this.contract_number) {
                    formData.append('contract_number', this.contract_number)
                }
                if (this.date_contract) {
                    formData.append('date_contract', this.date_contract)
                }
                if (this.description) {
                    formData.append('description', this.description)
                }
                if (this.active) {
                    formData.append('active', 1)
                }
                if (this.deleted) {
                    formData.append('deleted', 1)
                }
                if (this.new_contract) {
                    formData.append('new_contract', 1)
                }
                if (this.contract) {
                    formData.append('contract', this.contract)
                }

                if (this.data_contract.size) {
                    formData.append('contract_size', this.data_contract.size)
                }
                if (this.data_contract.file_name) {
                    formData.append('contract_file_name', this.data_contract.file_name)
                }
                if (this.data_contract.file_type) {
                    formData.append('contract_file_type', this.data_contract.file_type)
                }

                if (this.clients && this.clients.length > 0) {
                    for (let i in this.clients) {
                        if (this.clients[i].id !== undefined && this.clients[i].id > 0) {
                            formData.append(`clients[${i}]`, this.clients[i].id)
                        }
                    }
                }

                if (this.logo) {
                    if (this.logo.length > 250) {
                        var mimeTypeLogo = this.getMimeType(this.logo)
                        var blobLogo = this.dataURL64toBlob(this.logo)
                        if (mimeTypeLogo && blobLogo) {
                            formData.append('logo', blobLogo, mimeTypeLogo)
                        }
                    } else {
                        formData.append('logo', this.logo)
                    }
                }

                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`admin/company/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('company_has_been_updated'));
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('company_has_not_been_updated'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/company', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('company_has_been_added'));
                            this.$router.push({
                                name: 'company'
                            })
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'company.profile',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'company'
                                })
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('company_has_not_been_added'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            async getPaymentAccountCompanies() {
                let params = {};
                params.filter = 'all';
                params.company_uuid = this.company_uuid;
                await this.$http
                    .get(`admin/company/payment_account`, {
                        params: params,
                    })
                    .then(res => {
                        this.paymentAccountCompaniesItems = res.body.data
                        if(this.paymentAccountCompaniesItems.length === 0){
                            this.addBank = true
                        }
                    })
                    .catch(err => {
                        this.paymentAccountCompaniesItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_payment_account_companies'))
                    });
            },
            async savePaymentAccountCompany(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.company_uuid) {
                    formData.append('company_uuid', this.company_uuid)
                }
                if (this.currency) {
                    formData.append('currency', this.currency)
                }

                if (this.payment_account) {
                    formData.append('payment_account', this.payment_account)
                }
                if (this.bik_bank) {
                    formData.append('bik_bank', this.bik_bank)
                }
                if (this.bank_name) {
                    formData.append('bank_name', this.bank_name)
                }

                // Save
                await this.$http
                    .post(`admin/company/payment_account`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('payment_account_company_has_been_updated'));
                        if (res && res.body && res.body.status && res.body.status === 'success' ) {
                            this.getPaymentAccountCompanies();
                            this.bank_name = null;
                            this.bik_bank = null;
                            this.payment_account = null;
                            this.currency = null;
                            this.addBank = false
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('payment_account_has_not_been_updated'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async deletePaymentAccountCompany(item) {
                if (confirm(this.$t('delete_payment_account_company'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/company/${this.company_uuid}/payment_account/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('payment_account_company_has_been_deleted'))
                            this.getPaymentAccountCompanies()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('payment_account_company_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            showDataContract(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            previewContract() {
                let document = this.contract;
                this.data_contract = {}
                this.data_contract.url = URL.createObjectURL(document)
                this.data_contract.file_name = document.name
                this.data_contract.blob =  document
                this.data_contract.size = document.size
                this.data_contract.file_type = document.type
                this.new_contract = 1;
            },
            clearContract(){
                this.contract = null
                this.data_contract  = {}
            },
            addContract(){
                document.getElementById('contract_input').click()
            },
            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },

            async saveField(field, value) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if(field === 'phones'){
                    this.phones.forEach(function callback(item, key) {
                        formData.append(`phones[${key}][phone]`, item.phone)
                        formData.append(`phones[${key}][field]`, item.field)
                    });
                }
                else if(field === 'addresses'){
                    this.addresses.forEach(function callback(item, key) {
                        formData.append(`addresses[${key}][address]`, item.address)
                        formData.append(`addresses[${key}][office]`, item.office)
                    });
                }
                else if(field === 'emails'){
                    this.emails.forEach(function callback(item, key) {
                        formData.append(`emails[${key}]`, item.email)
                    });
                }
                else{
                    formData.append(field, value)
                }

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/company/${this.$route.params.id}/field`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('company_has_been_updated'))

                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('company_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }

            },
            async CompanyTextUpdate() {
                this.saveField('note',this.text_company);
                this.text_company_edit = false
            },
        }
    }
</script>
